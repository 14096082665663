@import "palette";

$theme-colors: (
    "light-shades": $color1,
    "light-accent": $color2,
    "main": $color3,
    "dark-accent": $color4,
    "dark-shades": $color5,
    "primary": $color3,
    "secondary": $color2,
    "success": $custom-success,
    "info": $custom-info,
    "warning": $custom-warning,
    "danger": $custom-danger,
    "patreon": $custom-patreon,
    "light": #f8f9fa,
    "dark": #343a40
);

$body-color: $color5;

/* pt-sans-caption-regular - latin_cyrillic */
@font-face {
    font-family: 'PT Sans Caption';
    font-style: normal;
    font-weight: 400;
    src: local('PT Sans Caption'), local('PTSans-Caption'),
        url('/fonts/pt-sans-caption-v12-latin_cyrillic-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/pt-sans-caption-v12-latin_cyrillic-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-regular - latin_cyrillic */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url('/fonts/montserrat-v14-latin_cyrillic-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('/fonts/montserrat-v14-latin_cyrillic-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

$font-family-base: 'Montserrat', sans-serif;
$headings-font-family: 'PT Sans Caption', sans-serif;
$headings-font-weight: 400;

$grid-breakpoints: (
  xs: 0,
  sm: 870px,
  md: 1160px,
  lg: 1740px,
  xl: 3480px
) !default;

@import "../bootstrap/scss/bootstrap";

.dynamic-shadow {
    @extend .shadow-sm;
    transition: box-shadow .3s;
}

.dynamic-shadow:hover {
    @extend .shadow;
}

.heading-font {
    font-family: $headings-font-family;
}

.popover {
    z-index:1019;
}

.settings-button {
    @extend .btn;
    border-radius: 20px;
    min-width: 150px;
    -webkit-appearance: none;
}

p {
    text-align: left !important;
}

img {
    max-width: 100%;
    height: auto; 
}

pre {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    overflow: auto;
}

.icode {
    @extend code;
    background: #f7f7f7;
    color: $color5;
    white-space: nowrap;
    margin-right: 0.05em;
    margin-left: 0.05em;
    padding-right: 0.4em;
    padding-left: 0.4em;
    border-radius: 3px;
}

.collapse-code {
    @extend .alert;
    padding: 0rem;
}

.code-heading {
    padding: 0.5rem 0.75rem;
    font-weight: 700;
}

.ads-border {
    @extend .border;
    border-width: 0.6em !important;
}

.code-heading .code-up{display:inline}
.code-heading .code-down{display:none}
.code-heading:not(.collapsed) .code-up{display:inline}
.code-heading:not(.collapsed) .code-down{display:none}
.code-heading.collapsed .code-up{display:none}
.code-heading.collapsed .code-down{display:inline}